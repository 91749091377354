import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

import Helpers from '/src/global/js/helpers/helpers.js';

Swiper.use([Navigation, Autoplay, Pagination]);
function swiperInit() {
    const allSwiperBlocks = document.querySelectorAll('[data-swiper]');

    // exit function if there isn't any slider
    if (allSwiperBlocks.length === 0) {
        return null;
    }

    allSwiperBlocks.forEach(function (block) {
        const buttonNext = block.querySelector('.js-swiper-btn-next');
        const buttonPrev = block.querySelector('.js-swiper-btn-prev');
        const pagination = block.querySelector('.js-swiper-pagination');

        const params = Helpers.getJsonFromAttr(block.dataset.swiper);

        const swiperParams = {
            loop: false,
            speed: 600,
            autoplay: false,
            slidesPerView: 1,
            spaceBetween: 32,
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
            },
            slideChange: function (swiper) {},

            // override default settings
            ...params,
        };

        const swiper = new Swiper(block, swiperParams);

        swiper.on('slideChange', function (e) {
            var imageLoading = swiper.el.querySelector(
                '[data-swiper-slide-index="' +
                    swiper.realIndex +
                    '"] img.lazyloading',
            );

            if (imageLoading) {
                imageLoading.classList.remove('lazyloading');
                imageLoading.classList.add('lazyloaded');
            }
        });
    });
}

export default swiperInit;
