import AOS from 'aos';

class FilterTypeTwo {
    constructor(block) {
        this.container = block;

        this._initProps();
        this._initElements();
        this._updateStateFromURL();
        this._initStates();
        this._initListeners();
    }

    /**
     * All needed parameters of the block are set up here
     *
     * @private
     */
    _initProps() {
        // Main organism's class
        this.componentClass = 'o-filter-2';

        // Block Top offset
        this.blockOffsetTop = 70;

        // Data from backend
        // URL for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxUrl = filterTypeTwo.url;
        // Name of the action
        // eslint-disable-next-line no-undef
        this.ajaxAction = filterTypeTwo.action;
        // Nonce code for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxNonce = filterTypeTwo.nonce;

        // Current data of the filters form
        this.formData = {};

        // Does the form includes submit button. Affects the pills functionality
        this.includesSubmitButton = null;
        // Are we currently waiting for AJAX response?
        this.isProcessingAjax = false;

        // Current state
        this.queryState = {
            industry: '',
            capability: '',
            category: '',
            service: '',
        };
    }

    /**
     * All DOM elements within the main Block which we are going to use are set up here
     *
     * @private
     */
    _initElements() {
        // Form
        this.filtersForm = this.container.querySelector(
            `[data-role='filters-form']`,
        );
        // Search Input
        this.searchInput = this.container.querySelector(`input[name='search']`);
        // Taxonomies multiselect dropdown
        this.termsDropdowns = this.container.querySelectorAll(
            `select[data-role='multiselect-dropdown']`,
        );
        // Taxonomies dropdown pills
        this.termsDropdownPillsContainer = this.container.querySelector(
            `[data-role='dropdown-pills-container']`,
        );
        // Taxonomies pills
        this.termsPills = this.container.querySelectorAll(
            `[data-role='terms-pills']`,
        );
        // Taxonomies Pills 'Clear All' buttons
        this.termsPillsClearButtons = this.container.querySelectorAll(
            `[data-role='terms-clear-all']`,
        );
        // Taxonomies Select
        this.termsSelects = this.container.querySelectorAll(
            `[data-role='terms-select']`,
        );
        // Form Clear Button
        this.formClearButton = this.container.querySelector(
            `[data-role='clear-form']`,
        );
        // Submit Button
        this.submitButton = this.container.querySelector(
            `input[data-role='submit-form']`,
        );
        // The main container of posts
        this.filterList = this.container.querySelector(
            `[data-role='posts-list']`,
        );
        // Page Input
        this.pageInput = this.container.querySelector(`[name='page']`);
        // Is Pagination input. Helps to distinguish pagination query from other queries
        this.paginationInput = this.container.querySelector(
            `[name='isPagination']`,
        );
        // Pagination Container
        this.paginationBlock = this.container.querySelector(
            `[data-role='pagination-block']`,
        );
        // Total posts count input
        this.totalPostsCountInput = this.container.querySelector(
            `[name='totalPostsCount']`,
        );
        // Clear All Dropdowns button
        this.clearAllDropdownsButton = this.container.querySelector(
            `[data-role='clear-all-dropdowns']`,
        );
    }

    /**
     * Modifies the state parameter of the class, setting values from the URL query params
     */
    _updateStateFromURL() {
        // Current URL query params
        const urlParams = new URLSearchParams(window.location.search);

        // If it's been initialized, it means that we should prioritize URL query params over the preselected values in the settings area
        for (const key in this.queryState) {
            // Value for the current parameter from the URL string
            let value = urlParams.get(key);
            value = value !== null ? String(value).toLowerCase() : '';
            this.queryState[key] = this._getNumbersArrayFromQueryParam(value);
        }
    }

    /**
     * Modifies the current URL by changing query params according to form data
     * @private
     */
    _updateUrlFromState() {
        const urlParams = new URLSearchParams(window.location.search);

        // Set query parameters from the object
        for (let key in this.queryState) {
            // Particularly it works for select fields only for this block
            const formDataKey = `${key}[]`;
            let formDataValue = this.formData[formDataKey] ?? '';

            if (this.formData[formDataKey]) {
                formDataValue = formDataValue.split(',');
                formDataValue =
                    this._getQueryParamStringFromNumbersArray(formDataValue);
            }
            if (formDataValue) {
                urlParams.set(key, formDataValue);
            } else {
                urlParams.delete(key);
            }
        }

        // Updating current URL
        let newURL =
            window.location.pathname +
            ([...urlParams.entries()].length > 0
                ? '?' + urlParams.toString()
                : '');
        newURL = decodeURIComponent(newURL);
        history.replaceState({}, '', newURL);
    }

    /**
     * Set certain states for elements on class init
     *
     * @private
     */
    _initStates() {
        // Terms Dropdown Functionality
        if (this.termsDropdowns.length) {
            [...this.termsDropdowns].map((select) => {
                $(select).multiselect({
                    columns: select.dataset.placeholder
                        ? parseInt(select.dataset.placeholder)
                        : 1,
                    search: parseInt(select.dataset.search) === 1,
                    selectAll:
                        parseInt(select.getAttribute('data-select-all')) === 1,
                    showCheckbox: parseInt(select.dataset.checkbox) === 1,
                    texts: {
                        placeholder: select.dataset.placeholder,
                        selectAll: select.getAttribute('data-select-all-text'),
                    },

                    replacePlaceholderText: false,
                    // eslint-disable-next-line no-unused-vars
                    onOptionClick: (select, input) => {
                        const options = select.querySelectorAll('option');
                        const multiselectContainer = select.closest(
                            `[data-module='multiselectDropdown']`,
                        );
                        const dropdown =
                            multiselectContainer.querySelector(
                                '.ms-options-wrap',
                            );
                        const inputs = multiselectContainer
                            ? multiselectContainer.querySelectorAll(
                                  `input[type='checkbox']`,
                              )
                            : [];
                        [...options].forEach((option) => {
                            option.selected =
                                option.value === input.value && input.checked;
                        });
                        [...inputs].forEach((inputElement) => {
                            const inputContainer =
                                inputElement.closest(`li[data-search-term]`);
                            const inputLabel = inputElement.closest('label');
                            if (inputContainer) {
                                if (
                                    inputElement.value === input.value &&
                                    input.checked
                                ) {
                                    inputElement.checked = true;
                                    inputContainer.classList.add('selected');
                                } else {
                                    inputElement.checked = false;
                                    inputContainer.classList.remove('selected');
                                    if (inputLabel) {
                                        inputLabel.classList.remove('focused');
                                    }
                                }
                            }
                        });
                        if (dropdown) {
                            dropdown.classList.remove('ms-active');
                        }

                        this._termsDropdownHandler();
                    },
                    onSelectAll: () => {
                        this._termsDropdownHandler();
                    },

                    // Customize the display of selected options
                    // onPlaceholder: function (select) {
                    //     updateSelectionDisplay(select);
                    // },
                });
                select.addEventListener(
                    'change',
                    this._termsDropdownHandler.bind(this),
                );
            });
        }
        function updateSelectionDisplay(select) {
            let selectedOptions = $(select).val();
            let displayText =
                'Selected: ' + (selectedOptions ? selectedOptions.length : 0);
            $(select)
                .siblings('.ms-options-wrap')
                .find('span')
                .text(displayText);
        }

        // Check whether form includes submit button or not
        this.includesSubmitButton = Boolean(this.submitButton);
        // Update the initial form data
        this._updateFormData();
        // Updating clear all button
        [...this.termsPills].map((pillsContainer) => {
            this._updatePillsClearAllButton(pillsContainer);
        });
        // Render pills for dropdowns if they exist and 'Dropdown + pills' option is chosen
        if (this.termsDropdownPillsContainer) {
            this._dropdownPillsUpdate();
        }
    }

    /**
     * All events listeners are listed here
     *
     * @private
     */
    _initListeners() {
        // Form Submit button
        if (this.includesSubmitButton) {
            this.submitButton.addEventListener(
                'click',
                this._submitButtonHandler.bind(this),
            );
        }
        // Terms Dropdown Pills
        if (this.termsDropdownPillsContainer) {
            this.termsDropdownPillsContainer.addEventListener(
                'click',
                this._dropdownPillsHandler.bind(this),
            );
        }
        // Terms Pills Functionality
        if (this.termsPills.length) {
            [...this.termsPills].map((pillsContainer) => {
                const inputs = pillsContainer.querySelectorAll(
                    'input[type="checkbox"]',
                );
                if (!inputs.length) {
                    return false;
                }
                inputs.forEach((input) => {
                    input.addEventListener(
                        'change',
                        this._termsPillsHandler.bind(this),
                    );
                });
            });
        }
        // Terms Pills 'Clear/Select All' functionality
        if (this.termsPillsClearButtons.length) {
            [...this.termsPillsClearButtons].map((button) => {
                button.addEventListener(
                    'click',
                    this._termsPillsClearAllHandler.bind(this),
                );
            });
        }
        // Clear Form button
        if (this.formClearButton) {
            this.formClearButton.addEventListener(
                'click',
                this._clearFormButtonHandler.bind(this),
            );
        }
        // Pagination
        if (this.paginationBlock) {
            this.paginationBlock.addEventListener(
                'click',
                this._paginationHandler.bind(this),
            );
        }
        // Clear All Dropdowns
        if (this.clearAllDropdownsButton) {
            this.clearAllDropdownsButton.addEventListener(
                'click',
                this._clearAllDropdownsHandler.bind(this),
            );
        }
    }

    /**
     * Form Submit button click event handler
     *
     * @param event
     * @private
     */
    _submitButtonHandler(event) {
        // Preventing default behaviour
        event.preventDefault();

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Terms Dropdown changes handler
     *
     * @private
     */
    _termsDropdownHandler() {
        // Update Dropdown pills states
        if (this.termsDropdownPillsContainer) {
            this._dropdownPillsUpdate();
        }

        // Do nothing if we have the form Submit button
        if (this.includesSubmitButton) {
            return;
        }

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Pills handler
     *
     * @param event
     * @private
     */
    _termsPillsHandler(event) {
        // Do nothing when AJAX is processing
        if (this.isProcessingAjax) {
            event.preventDefault();
            return;
        }

        // Related Input
        const input = event.target;
        // Checked state after change
        const isChecked = input.checked;
        const pillsContainer = input.closest(`[data-role='terms-pills']`);

        // De-select all the rest inputs
        if (isChecked) {
            // All the checkbox within the container element
            const inputs = pillsContainer.querySelectorAll(
                `input[type='checkbox']`,
            );
            [...inputs].map((currentInput) => {
                // Change the input state
                currentInput.checked = currentInput === input;
                this._updatePillState(currentInput, currentInput === input);
            });
        }

        // Otherwise change UI for the current pill according to its new value
        this._updatePillState(input, isChecked);
        this._updatePillsClearAllButton(pillsContainer);

        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            // Sending AJAX request for getting posts
            this._requestPosts();
        }
    }

    /**
     * Select/Deselect all the checkboxes within a container
     *
     * @param container
     * @param isChecked
     * @private
     */
    _selectAllPills(container, isChecked = true) {
        if (!container) {
            return;
        }
        // All the checkbox that are need to be updated
        const inputs = container.querySelectorAll(`input[type='checkbox']`);
        [...inputs].map((input) => {
            // Change the input state
            input.checked = isChecked;
            this._updatePillState(input, isChecked);
        });
        this._updatePillsClearAllButton(container, !isChecked);
    }

    /**
     * 'Clear All' button functionality
     *
     * @param event
     * @private
     */
    _termsPillsClearAllHandler(event) {
        // Current Clear All Button
        const button = event.target;
        // Container with the related pills
        const pillsContainer = button.closest(`[data-role='terms-pills']`);
        // Select all the pills
        this._selectAllPills(pillsContainer, false);

        // If no submit button - send ajax request
        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            this._requestPosts();
        }
    }

    _updatePillsClearAllButton(pillsContainer, areAllPillsUnselected = null) {
        const button = pillsContainer.querySelector(
            `[data-role='terms-clear-all']`,
        );
        if (!button) {
            return;
        }
        button.disabled =
            areAllPillsUnselected !== null
                ? areAllPillsUnselected
                : this._areAllPillsUnselected(pillsContainer);
    }

    _areAllPillsSelected(pillsContainer) {
        let allChecked = true;
        const inputs = pillsContainer.querySelectorAll(
            `input[type='checkbox']`,
        );
        inputs.forEach((input) => {
            if (!input.checked) {
                allChecked = false;
            }
        });

        return allChecked;
    }

    _areAllPillsUnselected(pillsContainer) {
        let allUnchecked = true;
        const inputs = pillsContainer.querySelectorAll(
            `input[type='checkbox']`,
        );
        inputs.forEach((input) => {
            if (input.checked) {
                allUnchecked = false;
            }
        });

        return allUnchecked;
    }

    /**
     * Changing a pill UI according to it's state (active or not)
     *
     * @param input
     * @param isChecked
     * @private
     */
    _updatePillState(input, isChecked = true) {
        if (!input) {
            return;
        }
        const label = input.closest('label');
        if (!label) {
            return;
        }
        if (isChecked) {
            label.classList.remove('text-[#213141]', 'bg-[#E5F0F1]');
            label.classList.add('text-white', 'bg-[#213141]');
        } else {
            label.classList.remove('text-white', 'bg-[#213141]');
            label.classList.add('text-[#213141]', 'bg-[#E5F0F1]');
        }
    }

    /**
     * Collect the current form data and store them in the Class's parameter
     *
     * @private
     */
    _updateFormData() {
        let formData = new FormData(this.filtersForm);
        formData.append('action', this.ajaxAction);
        formData.append('_nonce', this.ajaxNonce);

        // For dealing with multiselect values we need to filter it
        const data = {};
        formData.forEach(function (value, key) {
            const prev = data[key];
            if (prev) {
                data[key] = `${prev}, ${value}`;
            } else {
                data[key] = value;
            }
        });

        this.formData = data;
        // Changing query params before according to updated form data
        this._updateUrlFromState();
    }

    _clearAllDropdownsHandler(event) {
        event.preventDefault();

        const button = event.target;
        [...this.termsDropdowns].map((select) => {
            // All the options
            const options = select.querySelectorAll('option');
            // Unselect all the options
            [...options].map((option) => {
                option.selected = false;
            });
            // Trigger change event on this select
            if (this.includesSubmitButton) {
                select.dispatchEvent(new Event('change'));
            }
            // Re-render multiselect element
            $(select).multiselect('reload');
        });

        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            this._requestPosts();
        }
    }

    /**
     * Sending AJAX request with parameters for getting posts list output
     *
     * @private
     */
    _requestPosts() {
        // Update current form data before sending it to backend
        this._updateFormData();

        // eslint-disable-next-line jquery/no-ajax
        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: this.formData,
            beforeSend: () => {
                this.isProcessingAjax = true;
                // Show loader
                this._updateLoaderState();
            },
            success: (response) => {
                // New output from backend (posts list)
                let postsListOutput = response.data.postsOutput;
                if (!postsListOutput) {
                    return;
                }
                // Update the whole posts list container
                this.filterList.innerHTML = postsListOutput;

                // Updating the total posts count list
                if (
                    response.data.totalPostsCount !== undefined &&
                    this.totalPostsCountInput
                ) {
                    this.totalPostsCountInput.value = parseInt(
                        response.data.totalPostsCount,
                    );
                }

                if (!this.paginationBlock) {
                    return;
                }
                // Pagination Block
                let paginationBlockOutput = response.data.paginationBlockOutput;
                if (!paginationBlockOutput) {
                    return;
                }

                // Update the whole pagination block container
                this.paginationBlock.innerHTML = paginationBlockOutput;
            },
            // eslint-disable-next-line no-unused-vars
            complete: (xhr) => {
                this.isProcessingAjax = false;
                // Hide loader
                this._updateLoaderState(false);
                if (this.paginationInput) {
                    this.paginationInput.value = 0;
                }
                // Scroll to the top of the block
                this._scrollToBlockTop();
                AOS.refresh();
            },
            error: (xhr) => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Show Loader above the main filter block. show = true - show loader; show = false - hide loader
     *
     * @param show
     * @private
     */
    _updateLoaderState(show = true) {
        if (show) {
            window.globalLoader.showLoader();
        } else {
            window.globalLoader.hideLoader();
        }
    }

    /**
     * Pagination functionality
     *
     * @param event
     * @private
     */
    _paginationHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='pagination-button']`);
        if (!button) {
            return;
        }

        const page = parseInt(button.getAttribute('data-value'));
        if (isNaN(page)) {
            return;
        }

        this.pageInput.value = page;
        if (this.paginationInput) {
            this.paginationInput.value = 1;
        }
        this._requestPosts();
    }

    /**
     * Catch clicks on pills container and update dropdowns selected options list
     *
     * @param event
     * @private
     */
    _dropdownPillsHandler(event) {
        // If we didn't click on a pill do nothing
        if (!event.target.closest(`[data-role='term-dropdown-pill']`)) {
            return;
        }
        // Otherwise preventing default behaviour
        event.preventDefault();

        // Current clicked pill
        const pill = event.target;
        // Term Name
        const name = pill.getAttribute('data-term');
        // Term ID of this pill
        const value = pill.getAttribute('data-value');
        // Looking for the related dropdown
        const relatedDropdown = this.container.querySelector(
            `select[name='${name}']`,
        );
        if (!relatedDropdown) {
            return;
        }
        // Looking for the related option within the found dropdown
        const relatedOption = relatedDropdown.querySelector(
            `option[value='${value}']`,
        );
        if (!relatedOption) {
            return;
        }
        // Unselect this option
        relatedOption.selected = false;
        // Updating multiselect for correct UI
        $(relatedDropdown).multiselect('reload');
        // Trigger event
        relatedDropdown.dispatchEvent(new Event('change'));
    }

    /**
     * Looping through the all terms select and re-render pills for selected options
     *
     * @private
     */
    _dropdownPillsUpdate() {
        if (!this.termsDropdowns.length || !this.termsDropdowns.length) {
            return;
        }
        // New inner of the pills container
        let result = '';

        // Looping through the all terms selects
        [...this.termsDropdowns].map((select) => {
            // Current Term slug
            const term_slug = select.getAttribute('name');
            // All the options of this select
            const options = select.querySelectorAll('option');
            if (!options.length) {
                return false;
            }

            // Looping through the all options
            [...options].map((option) => {
                if (option.selected) {
                    result += this._getDropdownPillElement(
                        term_slug,
                        option.value,
                        option.innerText,
                    );
                }
            });
        });
        // Updating inner of the pills container
        this.termsDropdownPillsContainer.innerHTML = result;
    }

    /**
     * Renders a pill for dropdown + pills option
     *
     * @param term_slug
     * @param term_id
     * @param term_name
     * @returns {string}
     * @private
     */
    _getDropdownPillElement(term_slug = '', term_id = '', term_name = '') {
        if (!term_slug || !term_id || !term_name) {
            return '';
        }

        return `
            <button
                type="button"
                title="Unselect"
                class="${this.componentClass}__dropdown-pill flex justify-center items-center cursor-pointer w-auto px-3 py-1 rounded-lg border border-[#F05F50] border-solid text-base"
                data-term="${term_slug}"
                data-role="term-dropdown-pill"
                data-value="${term_id}">
                ✕ ${term_name}
            </button>
        `;
    }

    /**
     * Clear All button functionality
     *
     * @param event
     * @private
     */
    _clearFormButtonHandler(event) {
        event.preventDefault();

        // Clear Search input field
        if (this.searchInput) {
            this.searchInput.value = '';
        }

        // Reset terms Dropdowns
        if (this.termsDropdowns.length) {
            [...this.termsDropdowns].map((select) => {
                // All the options
                const options = select.querySelectorAll('option');
                if (!options.length) {
                    return false;
                }
                // Unselect all the options
                [...options].map((option) => {
                    option.selected = false;
                });
                // Trigger change event on this select
                if (this.includesSubmitButton) {
                    select.dispatchEvent(new Event('change'));
                }
                // Re-render multiselect element
                $(select).multiselect('reload');
            });
        }

        // Clear Pills
        if (this.termsPills.length) {
            [...this.termsPills].map((pillsContainer) => {
                this._selectAllPills(pillsContainer, false);
            });
        }

        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            this._requestPosts();
        }
    }

    /**
     * Smooth Scrolling to the top of the block. Better to use in mobile mode
     * @private
     */
    _scrollToBlockTop() {
        let bodyOffset = document.body.getBoundingClientRect().top;
        let blockOffset = this.container.getBoundingClientRect().top;

        let neededOffset = blockOffset - bodyOffset - this.blockOffsetTop;
        neededOffset = neededOffset > 0 ? neededOffset : 0;

        window.scrollTo({
            top: neededOffset,
            behavior: 'smooth',
        });
    }

    // todo
    _getNumbersArrayFromQueryParam(queryParamValue = '') {
        if (!queryParamValue) {
            return [];
        }
        return queryParamValue.split(',').map((item) => parseInt(item, 10));
    }

    _getQueryParamStringFromNumbersArray(array = []) {
        return array
            .map((item) => parseInt(item, 10))
            .filter((item) => item)
            .join(',');
    }
}

function filterType2() {
    const filterBlocks = document.querySelectorAll(
        `[data-role='filter-block-2']`,
    );
    if (!filterBlocks.length) {
        return;
    }
    filterBlocks.forEach((block) => {
        // eslint-disable-next-line no-unused-vars
        const blockClass = new FilterTypeTwo(block);
    });
}

export default filterType2;
