/*
 * Nav Dropdowns
 *
 * runs the functionality of clicking the arrow in the mobile menu dropdown
 */

function navMob($) {
    const selector = '.js-nav-drop';
    const activeClass = 'is-active';

    function toggleDropdown(element) {
        $(element).toggleClass('m-nav__toggle-wrapper--active');
        // eslint-disable-next-line jquery/no-slide
        $(element).toggleClass(activeClass).next('ul').slideToggle(250);
        $(element)
            .children('.js-plus-toggle')
            .toggleClass('a-plus-toggle--opened');
    }
    $(selector).on('click keydown', function (event) {
        if (
            event.type === 'click' ||
            (event.type === 'keydown' && event.key === 'Enter')
        ) {
            toggleDropdown(this);
        }
    });
}

export default navMob;
