import AOS from 'aos';
function showAccordion() {
    // CSS classes of Accordion items.
    const selector = {
        block: '.js-accordion',
        group: '.js-accordion_group',
        heading: '.js-accordion_heading',
        link: '.js-accordion_link',
        text: '.js-accordion_text',
        track: '.js-accordion_track',
    };

    // Active state classes.
    const state = {
        groupActive: 'is-active',
        openFirst: 'js-open-first',
        trackActive: 'rotate-45',
    };

    /**
     * Toggles the active class, rotates the control track, and slides the text element up or down.
     * @function
     * @param {Event} e - The click event.
     */
    const toggleAccordion = (e) => {
        const group = e.target.closest(selector.group);
        const text = group.querySelector(selector.text);
        const track = group.querySelector(selector.track);

        group.classList.toggle(state.groupActive);
        track.classList.toggle(state.trackActive);
        $(text).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // refresh Animation on Scroll module Events
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    };

    /**
     * Disable scroll page to #hash on click
     * Remove Hash from URL when close Accordion
     * @function
     **/
    const linkClick = (e) => {
        const group = e.target.closest(selector.group);
        e.preventDefault();

        let newURL = '';
        if (group.classList.contains(state.groupActive)) {
            const pageHashTarget = window.location.hash;
            newURL = window.location.href.replace(pageHashTarget, '');
        } else {
            newURL = e.currentTarget.href;
        }
        window.history.pushState({ path: newURL }, '', newURL);
    };

    /**
     * Sets the initial state of the accordion based on the URL hash and the 'openFirst' class on the accordion blocks.
     * @function
     */
    const setAccordionInitialState = () => {
        // The element that matches the URL hash.
        const anchor = location.hash
            ? document.querySelector(location.hash)
            : null;

        // The accordion blocks.
        const items = document.querySelectorAll(selector.block);

        // Loop through each accordion block.
        items.forEach((item) => {
            if (anchor && anchor.closest(selector.block) === item) {
                // If there is a matching URL hash, find the corresponding group and link and open the group.
                const group = anchor.closest(selector.group);
                const link = group.querySelector(selector.link);

                setTimeout(() => {
                    // Scroll to the top of the group.
                    window.scrollTo({
                        top: group.getBoundingClientRect().top + window.scrollY,
                    });
                    link.click();
                }, 700);
            } else if (item.classList.contains(state.openFirst)) {
                // If the block has the 'openFirst' class, find the first heading and open it.
                const heading = item.querySelector(
                    `${selector.group}:nth-child(1) ${selector.heading}`,
                );

                setTimeout(() => {
                    heading.click();
                }, 700);
            }
        });
    };

    /**
     * Adds the toggleAccordion function as a click event listener to each heading element.
     */
    document.querySelectorAll(selector.heading).forEach((heading) => {
        heading.addEventListener('click', toggleAccordion);
    });

    /**
     * Adds the Accordion click function as a click event listener to each heading link element.
     */
    document.querySelectorAll(selector.link).forEach((link) => {
        link.addEventListener('click', linkClick);
    });

    /**
     * Run setAccordionInitialState function when the DOM is ready
     */
    $(document).ready(() => {
        setAccordionInitialState();
    });
}

export default showAccordion;
