import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * ProgressBar
 *
 * @see https://codepen.io/Mamboleoo/pen/abdwYaJ
 */
function progressBar() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('progress', {
        value: 100,
        ease: 'none',
        scrollTrigger: { scrub: 0.3 },
    });
}

export default progressBar;
