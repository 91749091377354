/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Selector for the hamburger icon.
 * @constant {string}
 */
const selector = '.js-hamburger';

/**
 * Class name for the active state of the hamburger icon.
 * @constant {string}
 */
const activeClass = 'is-active';

/**
 * Class name for the active state of the body element when mobile nav is shown.
 * @constant {string}
 */
const bodyActiveClass = 'h-scroll-lock nav-active';

/**
 * Class name for the active state of the header element when mobile nav is shown.
 * @constant {string}
 */
const headerActiveClass = 'o-header--hamburger-active';

/**
 * Selector for the slide-out navigation menu.
 * @constant {string}
 */
const navMenu = '.js-slide-out';

/**
 * Class name for the active state of the slide-out navigation menu.
 * @constant {string}
 */
const navMenuActiveClass = 'm-slide-out--active';

/**
 * Class name for the header search button.
 * @constant {string}
 */
const headerSearchButton = 'o-header__search-button';

/**
 * Class name for the search bar.
 * @constant {string}
 */
const searchBar = 'm-search-bar';

/**
 * Class name for the search bar.
 * @constant {string}
 */
const plusToggle = '.js-nav-drop';

/**
 * Class name for the search bar.
 * @constant {string}
 */
const navMenuItem = '.menu-item';

/**
 * Class name for the search bar.
 * @constant {string}
 */
const navMenuItemMainLevel = '.m-nav__item--level-0';

// Declaring common variables
let isActive;

/**
 * Handles the click event of the hamburger icon.
 */
export function hamburger() {
    handleInitialClasses();

    $(selector).click((e) => {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        // Set or remove tabindex based on the presence of 'navMenuActiveClass'
        if ($(navMenu).hasClass(navMenuActiveClass)) {
            $(navMenu).find(plusToggle).attr('tabindex', 0); // Add tabindex
        } else {
            $(navMenu).find(plusToggle).removeAttr('tabindex');
        }

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }

        let navMenuElement = document.querySelector(navMenu);
        navMenuElement.addEventListener('keydown', handleTabKey);

        toggleNavActiveClass(navMenuElement);
    });

    // Find the first tabbable element inside the navMenu
    let firstTabbable = $(navMenu).find(
        'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    )[0];

    // Ensuring slideout nav menu is next element focused after hamburger toggle button
    $(selector).keydown(function (e) {
        if (e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (isActive) {
                e.preventDefault();

                if (firstTabbable) {
                    firstTabbable.focus(); // Focus on the first tabbable element
                }
            }
        }
    });

    // Ensuring focus shifts back to hamburger toggle button when going back
    $(firstTabbable).keydown(function (e) {
        if (e.shiftKey && e.key === 'Tab') {
            isActive = $(navMenu).hasClass(navMenuActiveClass);

            if (isActive && document.activeElement === firstTabbable) {
                e.preventDefault();
                $(selector)[0].focus(); // [0] gets the DOM element from the jQuery object
            }
        }
    });

    $(window).on('resize', () => {
        if ($(window).width() >= 1140) {
            if ($('.o-header-1__hamburger').hasClass('is-active')) {
                $('.o-header-1__hamburger').trigger('click');
            }
        }
    });
}

/**
 * Handles key events to toggle the '.tab_key' class on a navMenuElements.
 *
 * @param {HTMLElement} menuElement - The menu element.
 */
export function handleNavItemFocus(menuElement) {
    let tabPressed = false;
    menuElement.addEventListener('keyup', (event) => {
        if (event.key === 'Tab' && !tabPressed) {
            menuElement.classList.add('tab_key');
            tabPressed = true;
        }
    });
    menuElement.addEventListener('keydown', (event) => {
        if (event.key === 'Tab' && tabPressed) {
            menuElement.classList.remove('tab_key');
            tabPressed = false;
        }
    });
}

/**
 * Toggles the '.tab_key' class on navMenuElements within a menu on key events.
 *
 * @param {HTMLElement} navMenuElement - The menu element.
 */
export function toggleNavActiveClass(navMenuElement) {
    var menuElements = navMenuElement.querySelectorAll(
        `${navMenu} a${navMenuItem}, ${navMenu} ${plusToggle}`,
    );
    if (menuElements) {
        menuElements.forEach((menuElement) => {
            handleNavItemFocus(menuElement);
        });
    }
}

/**
 * Tracks last tabbed menu item element or submenu plus toggler and ensures when either of them is tabbed focus comes back to hamburger element which toggles slide out menu.
 */
export function handleTabKey(e) {
    const tabKey = e.key === 'Tab';
    const shiftKey = e.shiftKey;
    const manuItemsMainLevel = document.querySelectorAll(
        `${navMenu} li${navMenuItemMainLevel}`,
    );
    const menuItems = document.querySelectorAll(`${navMenu} ${navMenuItem}`);
    const plusToggles = document.querySelectorAll(`${navMenu} ${plusToggle}`);

    // Grabbing last items
    const lastMainLevelItem = manuItemsMainLevel[manuItemsMainLevel.length - 1];
    const lastMenuItem = menuItems[menuItems.length - 1];
    const lastPlusToggle = plusToggles[plusToggles.length - 1];

    // Check if the active element is inside the last manuItemsMainLevel list item
    const isInsideLastMainLevelItem = lastMainLevelItem.contains(
        document.activeElement,
    );

    // Giving focus to hamburger after last item
    if (tabKey && !shiftKey) {
        // Actions to be performed when inside the lastMainLevelItem
        if (isInsideLastMainLevelItem) {
            if (
                document.activeElement === lastMenuItem &&
                !lastPlusToggle.classList.contains('tab_key')
            ) {
                e.preventDefault();
                document.querySelector(selector).focus();
            }
            if (
                document.activeElement === lastPlusToggle &&
                !lastPlusToggle.classList.contains('is-active')
            ) {
                e.preventDefault();
                document.querySelector(selector).focus();
            }
        }
    }
}

/**
 * Makes sure plus toggle has active classes set for current page right away after landing on the page.
 */
function handleInitialClasses() {
    $(navMenuItem).each(function () {
        const $currentNavItem = $(this);
        const $navMenu = $currentNavItem.closest(navMenu);

        // Check if the current nav item has either "current_page_ancestor" or "current-menu-ancestor" (custom post type pages or links in menu recieve "current-menu-ancestor" class)
        if (
            ($currentNavItem.is('a.current_page_ancestor') ||
                $currentNavItem.is('a.current-menu-ancestor')) &&
            $navMenu.length
        ) {
            // Check if the current nav item has either "current_page_ancestor" or "current-menu-ancestor" as a class (custom post type pages or links in menu recieve "current-menu-ancestor" class)
            if (
                $currentNavItem.hasClass('current_page_ancestor') ||
                $currentNavItem.hasClass('current-menu-ancestor')
            ) {
                const $plusToggle = $currentNavItem.siblings(plusToggle);
                if (
                    !$plusToggle.hasClass('is-active') &&
                    !$plusToggle.hasClass('m-nav__toggle-wrapper--active')
                ) {
                    $plusToggle.addClass(
                        'm-nav__toggle-wrapper--active is-active',
                    );
                    $plusToggle
                        .children('.js-plus-toggle')
                        .addClass('a-plus-toggle--opened');
                }
            }
        }
    });
}
