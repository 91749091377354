/**
 * Get JSON from attr
 */
function getJsonFromAttr(jsonData) {
    if (jsonData) {
        // check if value is an object
        if (jsonData.match(/^{.*}$/)) {
            jsonData = JSON.parse(jsonData.replaceAll("'", '"'));
        } else {
            console.log(
                'Pass JSON object into data-swiper attribute! Remember to use Twig function "object_to_attr_json()"',
            );
        }
    }

    return jsonData;
}

export default getJsonFromAttr;
