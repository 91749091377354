/**
 * Attaches a click event handler to all elements with the class '.a-btn-scroll-down',
 * and animates smooth scrolling to the target anchor when clicked.
 */
function scrollToAnchor() {
    /**
     * Click event handler for elements with the class '.a-btn-scroll-down'.
     * Prevents the default anchor link behavior and smoothly scrolls to the target anchor.
     *
     * @param {Event} e - The click event object.
     */
    $('.a-btn-scroll-down').on('click', (e) => {
        e.preventDefault();

        // Get the target anchor from the 'href' attribute of the clicked button
        const target = $(e.currentTarget).attr('href');

        // Check if the target anchor exists on the page
        if ($(target).length) {
            // Calculate the offset of the target anchor from the top of the page
            const offsetTop = $(target).offset().top;

            const headerHeight = $('.js-header').height();

            // Animate scrolling to the target anchor with a smooth transition
            //if we need to consider the header height
            $('html, body').animate({ scrollTop: offsetTop - headerHeight }, 0);
            //if we do not need to consider the header height
            //$('html, body').animate({ scrollTop: offsetTop }, 0);
        }
    });
}

export default scrollToAnchor;
