function socialShare() {
    $('.js-social-share').on('click', function (e) {
        e.preventDefault();

        windowPopup($(this).attr('href'), 600, 400);
    });
}

function windowPopup(url, w, h) {
    // Fixes dual-screen position Most browsers Firefox
    const dualScreenLeft =
        window.screenLeft != undefined ? window.screenLeft : screen.left;
    const dualScreenTop =
        window.screenTop != undefined ? window.screenTop : screen.top;

    const left = screen.width / 2 - w / 2 + dualScreenLeft;
    const top = screen.height / 2 - h / 2 + dualScreenTop;

    window.open(
        url,
        '',
        `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`,
    );
}

export default socialShare;
