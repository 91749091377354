import '../../../../../static/vendors/multiselect/jquery.multiselect.css';

import { multiselect } from '../../../../global/js/vendors/multiselect/jquery.multiselect.js';

function multiselectDropdown() {
    // Dropdown Container
    const containers = document.querySelectorAll('.m-terms-multiselect');
    if (!containers.length) {
        return;
    }
    // Looping through all of them (we can have more than 1 on a page)
    [...containers].map((container) => {
        // Dropdowns
        const select = container.querySelector(
            "select[data-role='multiselect-dropdown']",
        );
        if (select) {
            $(select).multiselect({
                columns: select.dataset.placeholder
                    ? parseInt(select.dataset.placeholder)
                    : 1,
                search: parseInt(select.dataset.search) === 1,
                selectAll:
                    parseInt(select.getAttribute('data-select-all')) === 1,
                showCheckbox: parseInt(select.dataset.checkbox) === 1,
                texts: {
                    placeholder: select.dataset.placeholder,
                    selectAll: select.getAttribute('data-select-all-text'),
                },
            });
        }

        // Clear Button
        const clearButton = container.querySelector(
            "[data-role='dropdown-clear']",
        );
        if (clearButton) {
            clearButton.addEventListener('click', (event) => {
                // Preventing default behaviour
                event.preventDefault();

                // Related Dropdown
                const relatedDropdown = select;
                if (!relatedDropdown) {
                    return false;
                }
                // All the options
                const options = relatedDropdown.querySelectorAll('option');
                if (!options.length) {
                    return false;
                }
                // Unselect all the options
                [...options].map((option) => {
                    option.selected = false;
                });
                // Trigger change event on this select
                relatedDropdown.dispatchEvent(new Event('change'));
                // Re-render multiselect element
                $(relatedDropdown).multiselect('reload');
            });
        }

        // Clear All Button
        const clearAllButton = container.querySelector(
            "[data-role='dropdown-clear-all']",
        );
        if (clearAllButton) {
            clearAllButton.addEventListener('click', (event) => {
                // Preventing default behavior
                event.preventDefault();
                // Call the function to clear all selects
                clearAllMultiselects();
            });
        }
    });
}

function clearAllMultiselects() {
    // All the multiselect selects
    const allSelects = document.querySelectorAll(
        "select[data-role='multiselect-dropdown']",
    );

    if (!allSelects.length) {
        return;
    }

    // Loop through all selects
    [...allSelects].map((select) => {
        // All the options
        const options = select.querySelectorAll('option');
        if (!options.length) {
            return;
        }

        // Unselect all the options
        [...options].map((option) => {
            option.selected = false;
        });

        // Trigger change event on this select
        select.dispatchEvent(new Event('change'));

        // Re-render multiselect element
        $(select).multiselect('reload');
    });
}

export default multiselectDropdown;
