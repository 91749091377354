/*
 * ScrollToTop
 *
 * runs the functionality of the scroll to top button that appears at the bottom right of the website
 */
/* eslint-disable */

function scrollToTop() {
    let speed = 0;
    let selector = '.js-scroll-to-top';
    let activeClass = 'a-scroll-to-top--active';
    let activeDistance = 200;
    let scrollTo = 0;

    function scrollToTopAction() {
        $('html, body').animate(
            {
                scrollTop: scrollTo,
            },
            speed,
        );
        return false;
    }

    function handleScroll() {
        if ($(window).scrollTop() > activeDistance) {
            $(selector).addClass(activeClass);
        } else {
            $(selector).removeClass(activeClass);
        }
    }

    $(window).scroll(handleScroll);

    $(selector).click(scrollToTopAction);

    $(document).on('keydown', selector, function (event) {
        // Check if the key pressed is the "Enter" key (key code 13)
        if (event.keyCode === 13) {
            scrollToTopAction();
        }
    });
}

export default scrollToTop;
