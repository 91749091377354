function badgesA6() {
    const hooks = {
        loadMoreBtn: 'js-load-more',
        hiddenBadge: 'js-hidden-badge',
    };

    $(`.${hooks.loadMoreBtn}`).on('click', function () {
        const loadMoreButton = $(this);

        loadMoreButton.animate(
            {
                opacity: 0,
                scale: 0,
            },
            200,
            () => {
                //loadMoreButton.addClass('hidden');
                //loadMoreButton.prop('disabled', true);

                const hiddenBadges = $(`.${hooks.hiddenBadge}`);
                let faded = 0;

                hiddenBadges.each(function (index, badgeEl) {
                    // setTimeout(() => {
                    $(badgeEl).fadeTo(300, 1, () => {
                        faded++;
                        if (faded === 10 || faded >= hiddenBadges.length) {
                            if ($(`.${hooks.hiddenBadge}`).length === 0) {
                                loadMoreButton.addClass('hidden');
                                loadMoreButton.prop('disabled', true);
                            } else {
                                loadMoreButton.removeClass('hidden');
                                loadMoreButton.prop('disabled', false);
                                loadMoreButton.css('opacity', 1);
                                loadMoreButton.css('scale', 1);
                            }
                        }
                    });
                    // }, index * 100);

                    $(badgeEl).removeClass(hooks.hiddenBadge);

                    return index < 10;
                });
            },
        );
    });
}

export default badgesA6;
