/**
 * This function takes in a collection of elements, a class name, an optional exclude element and an optional list of events.
 *
 * @param {Array} elements - A collection of elements to add the class to.
 * @param {string} className - The class name to add to the elements.
 * @param {HTMLElement} [exclude=false] - An optional element to exclude from adding the class. In general used for `this` element in the loop.
 * @param {Array} [events=[]] - An optional list of events to dispatch on the elements after adding the class.
 */

function addClassVanillaJs(elements, className, exclude = false, events = []) {
    // Iterate over the elements
    elements.forEach(function (el) {
        // Check if the exclude parameter is provided and if the current element is not equal to the exclude element
        if (!exclude || (exclude && el != exclude)) {
            // Add the class name to the current element
            el.classList.add(className);

            // Iterate over the events and dispatch them on the current element
            events.forEach(function (event) {
                el.dispatchEvent(event);
            });
        }
    });
}

/**
 * This function is similar to the above function but it removes the class instead of adding it.
 *
 * @param {Array} elements - A collection of elements to remove the class from.
 * @param {string} className - The class name to remove from the elements.
 * @param {HTMLElement} [exclude=false] - An optional element to exclude from removing the class. In general used for `this` element in the loop.
 * @param {Array} [events=[]] - An optional list of events to dispatch on the elements after removing the class.
 */

function removeClassVanillaJs(
    elements,
    className,
    exclude = false,
    events = [],
) {
    // Iterate over the elements
    elements.forEach(function (el) {
        // Check if the exclude parameter is provided and if the current element is not equal to the exclude element
        if (!exclude || (exclude && el != exclude)) {
            // Remove the class name from the current element
            el.classList.remove(className);

            // Iterate over the events and dispatch them on the current element
            events.forEach(function (event) {
                el.dispatchEvent(event);
            });
        }
    });
}

export { addClassVanillaJs, removeClassVanillaJs };
