import AOS from 'aos';
import mixitup from 'mixitup';

function cardsD() {
    let containerEl = document.querySelector('.js-cards-filter');

    let mixer = mixitup(containerEl, {
        callbacks: {
            onMixEnd: function (state) {
                AOS.refresh();
                // Remove active class from all filter buttons except those with data-filter="all"
                document
                    .querySelectorAll('.filter-btn')
                    .forEach(function (btn) {
                        if (btn.getAttribute('data-filter') !== 'all') {
                            btn.classList.remove(
                                'bg-brand-color-7',
                                '!text-white',
                            );
                        }
                    });

                // Add active class to the currently active filter button
                let activeControl = document.querySelector(
                    '.mixitup-control-active',
                );
                if (
                    activeControl &&
                    activeControl.getAttribute('data-filter') !== 'all'
                ) {
                    activeControl.classList.add(
                        'bg-brand-color-7',
                        '!text-white',
                    );
                }
            },
        },
    });
}

export default cardsD;
