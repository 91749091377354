/**
 * Function to toggle a class on an element
 * @param {HTMLElement} element - element to toggle class on
 * @param {string} className - class to toggle
 */
function toggleClass(element, className) {
    if (element.classList.contains(className)) {
        element.classList.remove(className);
    } else {
        element.classList.add(className);
    }
}

export default toggleClass;
