import { Loader } from 'google-maps';

export default function () {
    const loader = new Loader(
        document.querySelector('.js-map').getAttribute('data-key'),
        {},
    );

    loader.load().then((google) => {
        document.querySelectorAll('.m-map-a').forEach(function (item) {
            const locations = item.querySelectorAll('.m-map-a__marker'),
                mapZoom = Number(item.dataset.zoom) ?? 15;

            const mapArgs = {
                zoom: mapZoom,
                center: _calculateMapCenter(locations),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                streetViewControl: false,
                // styles: [],
            };

            // Init map
            const map = new google.maps.Map(item, mapArgs);
            // Set map Markers
            const markers = _createMarkers(map, locations);
            // Change Map Zoom if needed
            _fixMapZoom(map, markers);

            /**
             * Bind custom Events
             **/
            // Map click Event
            map.addListener('click', () => {
                // close all InfoWindows on map click
                _deselectAllMarkers(markers);
            });

            // Markers Events
            markers.forEach(function (marker) {
                // Marker Click Event
                marker.addListener('click', () => {
                    let markerOpen = marker.open;
                    _deselectAllMarkers(markers);
                    if (!markerOpen) {
                        _setSelectedMarker(marker, map, markers);
                    }
                });

                // Marker InfoWindow close Event
                marker.infoWindow.addListener('closeclick', () => {
                    _deselectAllMarkers(markers);
                });
            });
        });

        /**
         * @param {object} map google.maps.Map
         * @param {HTMLAllCollection} locations
         * @return {array} google.maps.Marker collection
         **/
        function _createMarkers(map, locations) {
            let markers = [];
            locations.forEach(function (location) {
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(
                        Number(location.dataset.lat),
                        Number(location.dataset.lng),
                    ),
                    map,
                    icon: _getInactiveIconMarker(location),
                    // custom param for Inactive icon
                    iconInactive: _getInactiveIconMarker(location),
                    // custom param for Active icon
                    iconActive: _getActiveIconMarker(location),
                    infoWindow: new google.maps.InfoWindow({
                        maxWidth: 300,
                    }),
                });

                if (location.innerHTML) {
                    marker.infoWindow.setContent(location.innerHTML);
                }

                markers.push(marker);
            });
            return markers;
        }

        /**
         * @param {HTMLElement} marker
         * @return {object} valid Icon for google.maps.Marker
         **/
        function _getInactiveIconMarker(marker) {
            if (marker.dataset.inactive_icon) {
                const markerArgs = JSON.parse(marker.dataset.inactive_icon);
                return {
                    url: markerArgs.src,
                    size: new google.maps.Size(
                        markerArgs.width,
                        markerArgs.height,
                    ),
                    scaledSize: new google.maps.Size(
                        markerArgs.width,
                        markerArgs.height,
                    ),
                };
            }
            // fallback custom svg icon for the Marker
            return {
                path: 'M9.856 0c-1.52 0-3.04.316-4.327.948-2.066 1.015-3.845 2.84-4.814 4.94-.984 2.134-.948 6.367.08 9.383 1.383 4.056 3.553 7.958 6.916 12.433C8.661 28.967 9.63 30 9.865 30c.936 0 5.477-6.686 7.602-11.193 1.857-3.937 2.466-6.434 2.293-9.395-.223-3.824-2.182-6.797-5.577-8.464C12.896.316 11.376 0 9.856 0Zm.043 6.942c1.839-.013 3.78 1.562 3.622 4.212-.074.947-.678 2-1.426 2.49-2.583 1.692-5.882-.08-5.882-3.162 0-2.044 1.233-3.316 3.416-3.526a3.141 3.141 0 0 1 .27-.014z',
                fillColor: '#000',
                fillOpacity: 1,
                anchor: new google.maps.Point(9.894, 30),
                strokeWeight: 0,
                scale: 1,
            };
        }

        /**
         * @param {HTMLElement} marker
         * @return {object} valid Icon for google.maps.Marker
         **/
        function _getActiveIconMarker(marker) {
            if (marker.dataset.active_icon) {
                const markerArgs = JSON.parse(marker.dataset.active_icon);
                return {
                    url: markerArgs.src,
                    size: new google.maps.Size(
                        markerArgs.width,
                        markerArgs.height,
                    ),
                    scaledSize: new google.maps.Size(
                        markerArgs.width,
                        markerArgs.height,
                    ),
                };
            }
            // fallback custom svg icon for the Marker
            return {
                path: 'M9.856 0c-1.52 0-3.04.316-4.327.948-2.066 1.015-3.845 2.84-4.814 4.94-.984 2.134-.948 6.367.08 9.383 1.383 4.056 3.553 7.958 6.916 12.433C8.661 28.967 9.63 30 9.865 30c.936 0 5.477-6.686 7.602-11.193 1.857-3.937 2.466-6.434 2.293-9.395-.223-3.824-2.182-6.797-5.577-8.464C12.896.316 11.376 0 9.856 0Zm.043 6.942c1.839-.013 3.78 1.562 3.622 4.212-.074.947-.678 2-1.426 2.49-2.583 1.692-5.882-.08-5.882-3.162 0-2.044 1.233-3.316 3.416-3.526a3.141 3.141 0 0 1 .27-.014z',
                fillColor: '#2ae9ae',
                fillOpacity: 1,
                anchor: new google.maps.Point(9.894, 30),
                strokeWeight: 0,
                scale: 1,
            };
        }

        /**
         * Make all Markers Inactive
         * @param {array} markers google.maps.Marker collection
         **/
        function _deselectAllMarkers(markers) {
            markers.map((marker) => {
                marker.setIcon(marker.iconInactive);
                marker.infoWindow.close();
                marker.open = false;
            });
        }

        /**
         * Make Marker Active
         * @param {object} markerActive google.maps.Marker
         * @param {object} map google.maps.Map
         * @param {array} markers google.maps.Marker collection
         **/
        function _setSelectedMarker(markerActive, map, markers) {
            markers.map((marker) => {
                if (markerActive === marker) {
                    // map.panTo(marker.getPosition());
                    marker.setIcon(marker.iconActive);
                    marker.infoWindow.open(map, marker);
                    marker.open = true;
                }
            });
        }

        /**
         * Detect Map center for Markers
         * @param {HTMLAllCollection} locations
         * @return {object} google.maps.LatLng
         **/
        function _calculateMapCenter(locations) {
            let latitude = 0,
                longtitude = 0,
                minLat = 90,
                maxLat = -90,
                minLng = 180,
                maxLng = -180;

            locations.forEach(function (item) {
                let itemLat = Number(item.dataset.lat),
                    itemLng = Number(item.dataset.lng);
                minLat = itemLat <= minLat ? itemLat : minLat;
                maxLat = itemLat >= maxLat ? itemLat : maxLat;
                minLng = itemLng <= minLng ? itemLng : minLng;
                maxLng = itemLng >= maxLng ? itemLng : maxLng;
            });

            latitude = (minLat + maxLat) / 2;
            longtitude = (minLng + maxLng) / 2;

            return new google.maps.LatLng(latitude, longtitude);
        }

        /**
         * Change Map Zoom if it contains several Markers
         * @param {object} map google.maps.Map
         * @param {array} markers google.maps.Marker collection
         * @return {boolean} if Zoom changed
         **/
        function _fixMapZoom(map, markers) {
            if (markers.length <= 1) {
                return false;
            }
            let latlngbounds = new google.maps.LatLngBounds();
            for (var i = 0; i < markers.length; i++) {
                latlngbounds.extend(markers[i].position);
            }
            map.fitBounds(latlngbounds);
            return true;
        }
    });
}
