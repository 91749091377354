import { Power3, TimelineMax, TweenMax } from 'gsap';

import SplitText from '../../vendors/gsap-splittext/splittext';

/**
 * GSAP Split animation
 * class - .js-gsap-stagger
 */
function staggerAnimation() {
    const animatedElements = document.querySelectorAll('.js-gsap-stagger');

    // exit function if there isn't any split text animation
    if (animatedElements.length === 0) {
        return null;
    }

    animatedElements.forEach((el) => {
        const tlTitle = new TimelineMax({
                delay: 1.3,
                onStart: () => {
                    TweenMax.set(el, {
                        visibility: 'visible',
                    });
                },
            }),
            titleSplitted = new SplitText(el, {
                type: 'chars,words',
            });

        let titleWords = titleSplitted.chars;
        titleWords.forEach((letter) => (letter.ariaHidden = true));

        // Text animation
        tlTitle.staggerFrom(
            titleWords,
            1.0,
            {
                y: 10,
                autoAlpha: 0,
                rotation: 1,
                ease: Power3.easeOut,
            },
            0.05,
            '+=0',
        );
    });
}

export default staggerAnimation;
