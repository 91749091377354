function tableInit() {
    const elements = document.querySelectorAll(
        'main table:not(.not-responsive):not(.init)',
    );

    elements.forEach(function (element) {
        const tableContainer = document.createElement('div');
        const tableContainerScroll = document.createElement('div');

        tableContainer.classList.add('table-container');
        tableContainerScroll.classList.add('table-cantainer-scroll');

        element.parentElement.insertBefore(tableContainer, element);
        tableContainer.appendChild(element);

        element.parentElement.insertBefore(tableContainerScroll, element);
        tableContainerScroll.appendChild(element);

        element.classList.add('init');
    });

    tableOveflowCheck();

    window.addEventListener('resize', tableOveflowCheck);
}

function tableOveflowCheck() {
    const elements = document.querySelectorAll('.table-container');

    elements.forEach(function (container) {
        var containerWidth = container.offsetWidth;
        var tableWidth = container.querySelector('table').offsetWidth;

        if (containerWidth < tableWidth) {
            container.classList.add('overflow');
        } else {
            container.classList.remove('overflow');
        }
    });
}

export default tableInit;
