import MicroModal from 'micromodal';

export default () => {
    MicroModal.init({
        onShow: () => document.body.classList.add('l-body--modal-open'),
        onClose: () => document.body.classList.remove('l-body--modal-open'),
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });
};
